<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <selectComp
              placeholder="发放状态"
              :data="compensateStatusList"
              :hval="compensateStatus"
              @change="compensateStatusChange"
            />
            <inputComp
              placeholder="客户名称"
              :hval="customerName"
              @blur="customerNameBlur"
            />
            <inputComp
              placeholder="订单编号"
              :hval="orderCode"
              @blur="orderCodeBlur"
            />
            <inputComp
              placeholder="商品名称"
              :hval="commodityName"
              @blur="commodityNameBlur"
            />
            <!-- 战区 -->
            <salesArea
              :hval="salesArea"
              @change="salesAreaChange"
              @saleArea="saleArea"
            />
            <paramsDictNoForm
              placeholder="补偿原因"
              :hval="reason"
              @change="reasonChange"
              :parentCode="101453"
            />
            <dateComp
              :hval="date"
              startPlaceholder="补偿开始日期"
              endPlaceholder="补偿结束日期"
              @change="dateChange"
            />
            <!-- 商家搜索 -->
            <merchant
              showRankBtn
              :hval="storeName"
              @storeNameChange="storeNameChange"
              @rankClick="isShow = true"
            />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="customerName" label="客户名称/订单号">
            <template v-slot="scope">
              <div>{{ scope.row.customerName }}</div>
              <div>{{ scope.row.orderCode }}</div>
              <div></div>
            </template>
          </el-table-column>
          <el-table-column slot="delayDaysAndDelayNum" label="耽误时长(台.天)">
            <template v-slot="scope">
              <div>{{ scope.row.delayDays + '天' }}</div>
              <div>{{ scope.row.delayNum + '台' }}</div>
              <div></div>
            </template>
          </el-table-column>
          <el-table-column slot="num" label="下单数量/在租数量">
            <template v-slot="scope">
              <div>
                {{ scope.row.leaseQuantity || '-' }} /
                {{ scope.row.rentInQuantity || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="rentDate" label="起租日期/到期日期">
            <template v-slot="scope">
              <div>
                {{
                  scope.row.rentStartDate
                    ? scope.row.rentStartDate.slice(0, 10)
                    : '-'
                }}
                /
                {{
                  scope.row.rentEndDate
                    ? scope.row.rentEndDate.slice(0, 10)
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="leaseMode" label="租赁模式/租赁周期">
            <template v-slot="scope">
              <div>
                {{ scope.row.leaseMode || '-' }} /
                {{ scope.row.period || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            fixed="right"
            width="100"
          >
            <template v-slot="scope">
              <btnComp
                v-if="scope.row.compensateStatus === 0"
                btnType="text"
                @click="edit(scope.row, 'edit')"
                v-permission="'修改'"
                >修改</btnComp
              >
              <btnComp
                v-if="scope.row.compensateStatus === 0"
                btnType="text"
                @click="send(scope.row)"
                v-permission="'修改'"
                >发放</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="isShow = false" @selectStore="selectStore" />
    <add
      :isShow="addShow"
      @close="close"
      :info="info"
      :dialogType="dialogType"
    />
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import rank from '@/views/data-center/components/rank'
import paramsDictNoForm from '@/views/components/paramsDictNoForm'
import add from './add'
import { compensateList, compensateEdit } from '@/api'
export default {
  components: {
    merchant,
    salesArea,
    paramsDictNoForm,
    rank,
    add
  },
  data () {
    return {
      isShow: false,
      addShow: false,
      loading: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      dialogType: '', // 记录弹窗类型
      info: {},
      customerName: '',
      orderCode: '',
      commodityName: '',
      level: '',
      cityId: '',
      salesArea: [],
      reason: '',
      startTime: '',
      endTime: '',
      date: [],
      storeName: '',
      storeId: '',
      compensateStatus: '',
      compensateStatusList: [
        { label: '未发放', value: 0 },
        { label: '已发放', value: 1 }
      ],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { slot: 'customerName' },
        { prop: 'orderStatusName', label: '订单状态' },
        { prop: 'commodityName', label: '商品名称' },
        { prop: 'storeName', label: '商家' },
        { slot: 'num' },
        { slot: 'leaseMode' },
        { slot: 'rentDate' },
        { slot: 'delayDaysAndDelayNum' },
        { prop: 'reasonContent', label: '补偿原因' },
        { prop: 'compensateModeContent', label: '补偿方式' },
        { prop: 'compensateStatusName', label: '发放状态' },
        { prop: 'compensatePrice', label: '补偿金额(元)' },
        { prop: 'compensateDate', label: '发放日期' },
        { prop: 'operator', label: '操作人' }
      ]
    }
  },
  computed: {},
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        customerName: this.customerName,
        orderCode: this.orderCode,
        commodityName: this.commodityName,
        reason: this.reason,
        level: this.level,
        cityId: this.cityId,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime,
        compensateStatus: this.compensateStatus
      }
      this.loading = true
      compensateList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
      })
    },
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.$emit('saleArea', salesArea, level, cityId)
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    compensateStatusChange (val) {
      this.compensateStatus = val
    },
    customerNameBlur (val) {
      this.customerName = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    commodityNameBlur (val) {
      this.commodityName = val
    },
    reasonChange (val) {
      this.reason = val.code
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 修改弹窗
    edit (info, type) {
      this.dialogType = type
      this.addShow = true
      this.info = info
    },
    // 发放
    send (info) {
      const {
        reason,
        reasonOne,
        reasonTwo,
        reasonThree,
        delayNum,
        delayDays,
        orderId,
        compensateMode,
        compensatePrice,
        compensateDate,
        remark,
        orderCode,
        id
      } = info
      const params = {
        reason,
        reasonOne,
        reasonTwo,
        reasonThree,
        delayNum,
        delayDays,
        orderId: orderId === '0' ? '' : orderId,
        compensateMode,
        compensatePrice,
        compensateDate,
        remark,
        orderCode,
        id
      }
      compensateEdit({ ...params, compensateStatus: 1 }).then(_ => {
        this.$message.success('发放成功')
        this.getList()
      })
    },
    close () {
      this.addShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea

      this.customerName = ''
      this.orderCode = ''
      this.commodityName = ''
      this.reason = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.storeName = ''
      this.storeId = ''
      this.compensateStatus = ''
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
